import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  mode: any;
  collapse: boolean = false;

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.getFromParams(); 
  }

  getFromParams(): void {
    this.route.queryParams
      .subscribe(params => {
        this.mode = params.mode;
        // if (this.mode != 2) {
        //   this.goToHome();
        // }
      });
  }

  headerCollapse(){
    if(this.collapse) {
      this.collapse = false;
    }
  }

  chefPortal() {
    if (this.mode != 2) { 
      window.open('https://www.ayalyfe.com/pages/Chef-Requisition?mode=2', '_blank');
      //window.open('http://d29v4r1msgiqk7.cloudfront.net/pages/Chef-Requisition?mode=2', '_blank');
      //this.router.navigate(['../pages/Chef-Requisition'], { queryParams: { mode: 2 } });
    } else {
      this.onClick('1');
    }
  }

  goToHome() {
    if (this.mode != 2) {
      this.router.navigate(['../pages/Home']);
    }
  }

  signIn() {

  }

  onClick(val): void {
    switch (val) {
      case '1':
        window.open('http://d2enf6dnmojvjl.cloudfront.net/#/signup', '_blank');
        break;
      case '2':
        window.open('http://d2enf6dnmojvjl.cloudfront.net/#/', '_blank');
        break;
    }
  }

}
