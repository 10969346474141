import { Injectable } from '@angular/core';
import { CommonHttpService } from './../../../core/services/common-http.service';

@Injectable({
  providedIn: 'root'
})
export class PagesService {

  constructor(private commonHttpService: CommonHttpService) { }

  sendEmail(data: any) {
    return this.commonHttpService.post(`user/website_email`, data);
  }
}
