<nav class="navbar navbar-expand-lg stick" [ngClass]="collapse ? 'full-height' : ''">
  <div class="main">
    <div class="head">
      <div class="logo">
        <img class="upper" src="assets/logo.svg" alt="Aya Lyfe" width="200px" height="auto" (click)="goToHome()">
      </div>
      <div class="second-header">
        <a (click)="collapse=!collapse" class="navbar-toggler" data-toggle="collapse"
          data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
          aria-label="Toggle navigation">
          <img class="ham" [src]="!collapse ? 'assets/hmb.svg' : 'assets/cross.svg'" alt="aya">
        </a>
      </div>
    </div>
    <div class="">
      <nav class="navbar-light">
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto text-center">
            <li class="nav-item active" *ngIf='mode != 2'>
              <a class="nav-link" (click)="headerCollapse()" data-toggle="collapse"
                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation" routerLink="../pages/Home" routerLinkActive="active"
                #home="routerLinkActive">
                <img width="8px" height="8px" class="" src="assets/dot.svg" alt="" *ngIf="home.isActive">
                <span [style.opacity]="home.isActive ? '' : '0.7'" class=''>Home</span>
              </a>
            </li>
            <li class="nav-item" *ngIf='mode != 2'>
              <a (click)="headerCollapse()" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
                class="nav-link" routerLink="../pages/About-Us" [routerLinkActive]="['active']"
                #about="routerLinkActive">
                <img width="8px" height="8px" class="" src="assets/dot.svg" alt="" *ngIf="about.isActive">
                <span [style.opacity]="about.isActive ? '' : '0.7'" class=''>About Us</span></a>
            </li>
            <li class="nav-item" *ngIf='mode != 2'>
              <a (click)="headerCollapse()" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
                class="nav-link" routerLink="../pages/Plant-Powered" [routerLinkActive]="['active']"
                #plant="routerLinkActive">
                <img width="8px" height="8px" class="" src="assets/dot.svg" alt="" *ngIf="plant.isActive">
                <span [style.opacity]="plant.isActive ? '' : '0.7'" class=''>Plant Powered</span></a>
            </li>
            <!-- <li class="nav-item" *ngIf='mode != 2'>
              <a (click)="headerCollapse()" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
                class="nav-link" routerLink="" [routerLinkActive]="['active']" #blog="routerLinkActive">
                <span [style.opacity]="blog.isActive ? '' : '0.7'" class=''>Blog</span>
              </a>
            </li> -->
            <button type="button" class="button become-btn" (click)="chefPortal()">Become a Partner</button>
            <button *ngIf='mode == 2' type="button" class="button sign-btn" (click)="signIn()"
              (click)="onClick('2')">Sign
              In</button>
          </ul>
        </div>
      </nav>
    </div>
    <div class="first-header">
      <div class="d-flex align-items-center">
        <button type="button" class="button" (click)="chefPortal()">Become a Partner</button>
        <button type="button" class="button2 ml-2" (click)="signIn()" *ngIf='mode == 2' (click)="onClick('2')">Sign
          In</button>
      </div>
    </div>
  </div>
</nav>
