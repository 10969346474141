import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private loaderSubject = new Subject<boolean>();
  loaderState = this.loaderSubject.asObservable();
  constructor() { }

  // Show Loader
  show() {
    this.loaderSubject.next({ show: true } as any);
  }
  hide() {
    this.loaderSubject.next({ show: false } as any);
  }
}

