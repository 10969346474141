<div class="footer">
  <div class="eight" *ngIf='mode != 2'>
    <div class="eight-content">
      <div class="row">
        <div class="col-12 col-lg-7 text-left">
          <div class="input-form">
            <h2 class="mob-size">Sign up for newsletter</h2>
            <form [formGroup]="contactForm">
              <div class="input-box form-group input-field pt-3">
                <div class="input-footer">
                  <input type="email" class="form-control" id="exampleInputEmail1" formControlName="email"
                    aria-describedby="emailHelp" placeholder="Email Address">
                  <div *ngIf="contactForm.get('email').errors?.required && contactForm.get('email').touched"
                    class="error-message">Please enter
                    email!</div>
                </div>
                <div class="mt-4">
                  <button id="input-box" type="button" class="btn btn-secondary" (click)="onSubmit()"
                    [disabled]="!contactForm.valid">Subscribe Now</button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="col-12 col-lg-5 text-left position-relative">
          <div class="eight-text">
            <h2 class="">Contact us</h2>
            <div class="com">
              <img class="" src="assets/Send.svg" alt="">
              <p class="color-2" (click)="onClick('1')">support@ayalyfe.com</p>
            </div>
            <div class="socials">
              <p class="color-2">Follow us on</p>
              <div class="d-flex align-items-center">
                <!-- <img class="ml-2 ab" src="assets/twitter.svg" alt="" (click)="onClick('2')"> -->
                <img class="ml-2 ab" src="assets/link.svg" alt="" (click)="onClick('3')">
                <img class="ml-3 bc" src="assets/fb.svg" alt="" (click)="onClick('4')">
                <img class="ml-4 bc" src="assets/insta.svg" alt="" (click)="onClick('5')">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="first">
    <div class="row">
      <div class="col-12 col-lg-6 text-left">
        <div class="first-text">
          <p class="mob-size font-weight-bold">Download the AYA LYFE app</p>
          <div class="social">
            <img class="" src="assets/app-white.svg" alt="" (click)="onClick('6')">
            <img class="" src="assets/google-white.png" alt="" (click)="onClick('7')">
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 text-center">
        <div class="socials last" *ngIf='mode == 2'>
          <p class="p">Follow us on</p>
          <div class="d-flex align-items-center">
            <!-- <img class="ml-2 a" src="assets/twitter.svg" alt="" (click)="onClick('2')"> -->
            <img class="ml-2 a" src="assets/link.svg" alt="" (click)="onClick('3')">
            <img class="ml-3 b" src="assets/fb.svg" alt="" (click)="onClick('4')">
            <img class="ml-4 b" src="assets/insta.svg" alt="" (click)="onClick('5')">
          </div>
        </div>
        <div class="content">
          <p class="terms" (click)="goToTerms()">Terms & Conditions</p>
          <p class="privacy" (click)="goToPrivacy()">Privacy Policy</p>
          <p>© 2021 All Rights Reserved - AYA LYFE</p>
        </div>
      </div>
    </div>
  </div>
</div>
