import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { PagesService } from './../../../features/pages/services/pages.service';
import Swal from 'sweetalert2';
import { catchError } from 'rxjs/internal/operators/catchError';
import { of } from 'rxjs/internal/observable/of';
import { tap } from 'rxjs/internal/operators/tap';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  mode: any;
  contactForm: FormGroup;
  emailPattern = '^[a-zA-Z0-9._%+-]+@[A-Za-z0-9.-]+\\.[a-z]{2,4}$';

  constructor(private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute, private pagesService: PagesService) { }

  ngOnInit(): void {
    this.getFromParams();
    this.initContactForm();
  }

  getFromParams(): void {
    this.route.queryParams
      .subscribe(params => {
        this.mode = params.mode;
      });
  }

  initContactForm() {
    this.contactForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(this.emailPattern)])]
    });
  }

  onSubmit() {
    Swal.fire('Will get back to you shortly!', '', 'success');
    this.contactForm.reset();
    // const data = {
    //   email: this.contactForm.value.email
    // };
    // this.pagesService
    //   .sendEmail(data)
    //   .pipe(catchError(err => of(err)
    //   ), tap((resp: any) => {
    //     if (resp.status === 200) {
    //       this.contactForm.reset();
    //       Swal.fire('Link sent on your email id!', '', 'success');
    //     } else {
    //       Swal.fire({
    //         title: 'Something went wrong',
    //         width: 300,
    //         text: resp.error.message,
    //         icon: 'error',
    //       });
    //     }
    //   }),
    //   ).subscribe();
  }

  onClick(val): void {
    switch (val) {
      case '1':
        window.open('https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=support@ayalyfe.com', '_blank');
        break;
      case '2':
        window.open('https://twitter.com/?lang=en', '_blank');
        break;
      case '3':
        window.open('https://www.linkedin.com/company/aya-lyfe', '_blank');
        break;
      case '4':
        window.open('https://www.facebook.com/ayalyfe', '_blank');
        break;
      case '5':
        window.open('https://www.instagram.com/ayalyfe/', '_blank');
        break;
      case '6':
        // window.open("https://www.apple.com/in/app-store/", "_blank");
        Swal.fire('Coming Soon!', '', 'success');
        break;
      case '7':
        // window.open("https://play.google.com/store", "_blank");
        Swal.fire('Coming Soon!', '', 'success');
        break;
    }
  }

  goToTerms() {
    this.router.navigate(['../pages/Terms-Conditions'], { queryParams: { mode: this.mode } });
  }

  goToPrivacy() {
    this.router.navigate(['../pages/Privacy-Policy'], { queryParams: { mode: this.mode } });
  }

}



